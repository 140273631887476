import { render, staticRenderFns } from "./image.vue?vue&type=template&id=593d24fc&scoped=true"
import script from "./image.vue?vue&type=script&lang=js"
export * from "./image.vue?vue&type=script&lang=js"
import style0 from "./image.vue?vue&type=style&index=0&id=593d24fc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "593d24fc",
  null
  
)

export default component.exports